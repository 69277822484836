@font-face {
  font-family: Nunito;
  src: url("./font/Nunito/Nunito-Variable.woff2") format("woff2");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

* {
  font-family: Nunito, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0a0c10;
}

.react-resizable-handle {
  display: none;
}

.react-grid-item.react-grid-placeholder {
  background: #19191b;
  border-radius: 30px;
  transition-duration: 350ms;
  opacity: 0.5;
}

.react-grid-item {
  transition: all 300ms ease;
  transition-property: left, top, width, height, background;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.linkedin {
  animation-name: animate;
  animation-duration: 2s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.github {
  animation-name: animate2;
  animation-duration: 1.8s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.coming-soon {
  animation-name: neonGreenFlicker;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes animate {
  0% {
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.7))
      drop-shadow(0 0 2px rgba(128, 179, 255, 0.8))
      drop-shadow(0 0 8px rgba(77, 148, 255, 0.6))
      drop-shadow(0 0 20px rgba(0, 102, 255, 0.4));
  }

  100% {
    filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.8))
      drop-shadow(0 0 5px rgba(128, 179, 255, 0.9))
      drop-shadow(0 0 14px rgba(77, 148, 255, 0.7))
      drop-shadow(0 0 28px rgba(0, 102, 255, 0.5));
  }
}

@keyframes animate2 {
  0% {
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.7))
      drop-shadow(0 0 2px rgba(230, 191, 255, 0.8))
      drop-shadow(0 0 8px rgba(191, 128, 255, 0.6))
      drop-shadow(0 0 20px rgba(138, 43, 226, 0.4));
  }

  100% {
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.8))
      drop-shadow(0 0 4px rgba(230, 191, 255, 0.9))
      drop-shadow(0 0 12px rgba(191, 128, 255, 0.7))
      drop-shadow(0 0 25px rgba(138, 43, 226, 0.5));
  }
}

@keyframes neonGreenFlicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.7))
      drop-shadow(0 0 2px rgba(200, 255, 200, 0.8))
      drop-shadow(0 0 8px rgba(100, 255, 100, 0.6))
      drop-shadow(0 0 20px rgba(0, 255, 0, 0.4));
  }
  12%,
  20%,
  24%,
  55%,
  98% {
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.3))
      drop-shadow(0 0 1px rgba(200, 255, 200, 0.4))
      drop-shadow(0 0 3px rgba(100, 255, 100, 0.2))
      drop-shadow(0 0 8px rgba(0, 255, 0, 0.1));
  }
}

.layout {
  opacity: 0;
  transform: translateY(20px);
}

.wrapper {
  height: 20%;
  width: 60%;
  background: #555;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.skill {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: green;
  height: 80%;
  width: 20%;
  margin: 0;
  padding: 0;
  position: relative;
  flex-shrink: 0;
  color: black;
  font-size: 21px;
  cursor: pointer;
}
.box.active {
  color: white;
  border: 2px solid white;
}

.prev {
  position: absolute;
  top: 10%;
  left: 15%;
}
.next {
  position: absolute;
  top: 10%;
  right: 15%;
}
button {
  padding: 1rem 2rem 1rem 2rem;
  border: none;
  background: #555;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.toggle {
  position: absolute;
  top: 10%;
}

.show-overflow {
  overflow: visible;
}
